import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

function load(componentName) {
  return () => import(`@/components/${componentName}`)
}

const router = new VueRouter({
    routes: [
      { 
        path: '/', 
        name: 'Home',
        component: load('Home') 
      },
      { 
        path: '/reel',
        name: 'Reel',
        component: load('Reel')
      },
      { 
        path: '/project/:url', 
        name: 'Project',
        component: load('Project')
      },
      { 
        path: '/thanks', 
        name: 'success',
        component: load('Thanks') 
      },
      {
        path: '/uh-oh',
        name: 'fail',
        component: load('EpicFail')
      },
      { 
        path: '*', 
        name: 'Not Found',
        component: load('NotFound') 
      },
    ],
    mode: 'history',
    scrollBehavior (to, from, savedPosition) {
      const position = {};
  
      if (savedPosition) {
        position.x = savedPosition.x;
        position.y = savedPosition.y;
      } else {
      return new Promise(resolve => {
        this.app.$root.$once('scrollBeforeEnter', () => {
          resolve(position);
        });
      });
    }
  }


  });


export default router