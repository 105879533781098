import Vue from 'vue'
import App from './App.vue'
import VueScrollTo from 'vue-scrollto'
import VueScrollReveal from 'vue-scroll-reveal'
import vueScrollBehavior from 'vue-scroll-behavior'
import VueDayjs from 'vue-dayjs-plugin'
import * as VueGoogleMaps from 'vue2-google-maps'
import { Plugin } from 'vue-responsive-video-background-player'
import router from './routes'
import Vue2TouchEvents from 'vue2-touch-events'

//import "@/styles/_variables.scss"
//import "@/styles/global.scss"

Vue.use(VueScrollTo, {
  duration: 1500,
  easing: "ease",
})

Vue.use(VueScrollReveal, {
  class: 'enter',
  duration: 800,
  mobile: false
})

Vue.use(vueScrollBehavior, {
  router: router,
  maxLength: 50,
  delay: 2000
})

Vue.use(VueDayjs)

Vue.use(Plugin)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDmU45Vn2e0UMnpJvlFlnRcW6JNEn_kW38',
  },
  installComponents: true
})

Vue.use(Vue2TouchEvents)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

