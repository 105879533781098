<template>
  <div id="app">
      <transition name="fade" enter-active-class="fade-in" leave-active-class="fade-out" mode="out-in" @before-enter="beforeEnter" appear>
        <router-view class="view"></router-view>
      </transition>
  </div>
</template>

<script>

export default {
  methods: {
  beforeEnter () {
    this.$root.$emit('scrollBeforeEnter');
  }
}
}
</script>

<style scoped>

</style>